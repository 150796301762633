import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0915e0e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "servicesByocLabel",
    "modal-title": "Add number label",
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        label: _ctx.itemLabel,
        class: "mb-4"
      }, {
        default: _withCtx(() => [
          (_ctx.itemImage || _ctx.itemIcon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.itemImage)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "logo-item logo-item--rounded",
                      src: _ctx.itemImage
                    }, null, 8, _hoisted_2))
                  : _createCommentVNode("", true),
                (_ctx.itemIcon)
                  ? (_openBlock(), _createBlock(_component_tm_icon, {
                      key: 1,
                      name: _ctx.itemIcon,
                      size: "xLarge",
                      class: "distinct--text mr-2"
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
              ]))
            : (_openBlock(), _createBlock(_component_tm_country, {
                key: 1,
                country: {
            id: 'us',
            name: _ctx.label,
          }
              }, null, 8, ["country"]))
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_tm_form_line, { label: _ctx.valueLabel }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.labelValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.labelValue) = $event)),
            placeholder: "Enter label name",
            autofocus: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    "modal-footer-button": _withCtx(({ hideModal }) => [
      _createVNode(_component_tm_button, {
        color: "primary",
        size: "large",
        onClick: hideModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }))
}